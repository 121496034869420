import React, { useState, useContext, useEffect, useMemo } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import ProjectCard from "../components/project-card"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { Stack, Switch } from "@chakra-ui/core"
import { GlobalState } from "../types/types"

// fetch data
export const query = graphql`
query getProjectsImages {
  wpgraphql {
    page(id: "my-work", idType: URI) {
      children(first: 100) {
        edges {
          node {
            ... on WPgraphQL_Page {
              id
              title(format: RENDERED)
              menuOrder
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
              portfolioProject {
                fieldGroupName
                madeusing
                desc
                name
                type
                livesiteurl
                mainimagetag
                personalproject
                imagemain {
                  mediaItemUrl
                }
                imagephone {
                  mediaItemUrl
                }
                imagedesktop {
                  mediaItemUrl
                }
                imagetablet {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  }
}
`

const MyWorkPage = ({ data }) => {

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext) as GlobalState
  const [projectList, setProjectList] = useState([])

  // on load dispatch action to set the state for projectList
  useEffect(() => {

    if (!state?.projectsList) {
      dispatch({ type: "SET_PROJECT_LIST", payload: data });
    }
    else {
      setProjectList(state?.projectsList?.wpgraphql?.page?.children?.edges);
    }

  }, [state])


  const handleRemovePersonalProjects = () => {
    if (state.personalProjects) {
      dispatch({ type: "REMOVE_PERSONAL_PROJECTS" });
    }
    else {
      dispatch({ type: "ADD_PERSONAL_PROJECTS" });
    }
  }


  //search value state
  const [value, setValue] = useState("")
  const [error, setError] = useState(false)

  const handleChangeValue = e => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setValue(e.target.value)
      setError(false)
    } else {
      setError(true)
    }
  }


  const isSearched = (searchTerm: string) => (item) =>
    !searchTerm ||
    item.node.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.node.portfolioProject.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.node.portfolioProject.madeusing.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.node.portfolioProject.desc.toLowerCase().includes(searchTerm.toLowerCase())


  const searchResults = projectList ? projectList.filter(isSearched(value)) : [];

  useMemo(() => searchResults, [value])

  const topSection = (
    <div className="container myWork-intro-text">
      <div className="left-side">
        <h1>My work</h1>
        <p>
          For more examples of my work or if you have any questions regarding
          the projects below don't hesitate to{" "}
          <Link to="/contact-me/">contact</Link> me. </p> <p>You can also visit the{" "}
          <Link to="/about/">about me</Link> page to learn more. Recently
          completed projects to be added soon.
        </p>
      </div>
      <div className="right-side">
        <h2>Search Projects</h2>
        <p>You can search by any keyword relative to the projects e.g. "React, PHP, API".</p>
        <input
          type="text"
          name="project-search"
          value={value}
          onChange={handleChangeValue}
          placeholder="Key word"
        ></input>
        <Stack align="center" direction="row">
          <p className='no-margin'>Include personal projects</p>
          <Switch className={state.personalProjects ? 'active-switch' : ''} isChecked={state.personalProjects ? true : false} marginLeft="15px" onChange={handleRemovePersonalProjects} size="lg" />
        </Stack>
        <br></br>
        {error && (
          <p className="error-text">You can only search for text values.</p>
        )}
        {searchResults.length === 1 && (
          <p>{projectList.filter(isSearched(value)).length} Project found.</p>
        )}
        {searchResults.length > 1 && (
          <p>{projectList.filter(isSearched(value)).length} Projects found.</p>
        )}
      </div>
    </div>
  )

  if (searchResults.length > 0) {
    return (
      <Layout>
        <SEO title="My Work" />
        {topSection}
        <div className="projects-wrapper">
          {searchResults.sort(function (a, b) {
            return a.node.menuOrder - b.node.menuOrder;
          }).map(x => (
            <ProjectCard key={x.node?.id} data={x.node} />
          ))}
        </div>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <SEO title="My Work" />
        {topSection}
        <div className="container no-results">
          <p>
            No results found.<br></br>Please try again with different keywords
          </p>
        </div>
      </Layout>
    )
  }
}

export default MyWorkPage
