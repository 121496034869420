import React from "react"
import Modal from "react-modal"
import { FaTimesCircle } from "react-icons/fa"
import { IoMdArrowDropright } from "react-icons/io"

interface ProjectCardState {
  data: any
  modalIsOpen: boolean
}

interface ProjectCardProps {
  data: any
  modalIsOpen?: boolean
}

class ProjectCard extends React.Component<ProjectCardProps, ProjectCardState> {
  constructor(props: ProjectCardProps) {
    super(props)
    this.state = {
      data: props.data,
      modalIsOpen: false,
    }

    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  
    document.getElementsByTagName("aside")[0].style.zIndex = "0"
    document.getElementsByClassName("main-menu")[0].style.zIndex = "0"
    document.getElementsByClassName("bm-burger-button")[0].style.top = "-1000px"
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false })

    // document.getElementsByTagName("html")[0].style.overflowY="scroll";
    document.getElementsByTagName("aside")[0].style.zIndex = "5"
    document.getElementsByClassName("main-menu")[0].style.zIndex = "1"
    document.getElementsByClassName("bm-burger-button")[0].style.top = "28px"
  }

  render() {
    return (
      <div className="project-card-wrapper">
        <div onClick={this.openModal} className="project-card-inner">
          <div
            className={`project-card-front ${this.state.data?.portfolioProject?.personalproject?'personal-project':''} `}
            style={{
              backgroundImage: "url(" + this.state.data.featuredImage?.node?.mediaItemUrl + ")",
              backgroundSize: "cover",
            }}
          >
            {/* <div className = "text-front">{x.name}</div> */}
          </div>
          <div className="project-card-back">
            <h2>{this.state.data?.title}</h2>
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
          >
            <div className="container inner-modal">
              <div className="row">
                <div className="modal-col-text">
                  <h1>{this.state.data?.portfolioProject?.name}</h1>

                  <p>
                    {" "}
                    {this.state.data?.portfolioProject?.desc}
                    <br></br>{" "}
                  </p>

                  {/* <div className="tag">
                    <h2>{this.state.data?.portfolioProject?.type}</h2>
                  </div> */}

                  <div className="tag">
                    <h2 className="line-height-big">{this.state.data?.portfolioProject?.madeusing}</h2>
                  </div>

                  {this.state.data?.portfolioProject?.livesiteurl ? (
                    <a
                      href={this.state.data?.portfolioProject?.livesiteurl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit {this.state.data?.portfolioProject?.type} <IoMdArrowDropright />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div className="main-device">
                  <div className="main-device-inner">
                    <img
                      src={this.state.data?.portfolioProject?.imagemain?.mediaItemUrl}
                      alt={this.state.data.image}
                    ></img>
                    <h2>{this.state.data?.portfolioProject?.mainimagetag}</h2>
                  </div>
                </div>
              </div>

              <div className="row devices-row">
                <div className="device desktop">
                  <h2>Desktop</h2>
                  <img
                    src={this.state.data?.portfolioProject?.imagedesktop?.mediaItemUrl}
                    alt={this.state.data.name}
                  ></img>
                </div>

                <div className="device tablet">
                  <h2>Tablet</h2>
                  <img
                    src={this.state.data?.portfolioProject?.imagetablet?.mediaItemUrl}
                    alt={this.state.data.name}
                  ></img>
                </div>

                <div className="device phone">
                  <h2>Phone</h2>
                  <img
                    src={this.state.data?.portfolioProject?.imagephone?.mediaItemUrl}
                    alt={this.state.data.name}
                  ></img>
                </div>
              </div>
              <div className="exit-btn" onClick={this.closeModal}>
                <FaTimesCircle />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default ProjectCard
